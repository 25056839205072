/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */
$font-dir: "./fonts/";
$production-font-dir: "./fonts";

@font-face {
  font-family: "Ploni";
  src: url("#{$production-font-dir}/ploni-regular-aaa.woff2") format("woff"),
    url("#{$font-dir}/ploni-regular-aaa.woff2") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Ploni";
  src: url("#{$production-font-dir}/ploni-bold-aaa.woff2") format("woff"),
    url("#{$font-dir}/ploni-bold-aaa.woff2") format("woff");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Ploni";
  src: url("#{$production-font-dir}/ploni-demibold-aaa.woff2") format("woff"),
    url("#{$font-dir}/ploni-demibold-aaa.woff2") format("woff");
  font-style: normal;
  font-weight: 600;
}
// font
//@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Varela%20Round:wght@200;300;400;500;600;700;800&display=swap');

// alternative font: alef
//@import url('https://fonts.googleapis.com/css2?family=Alef:wght@200;300;400;500;600;700;800&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// 1st: SCSS variable overrides
$global-prefix: "";

// 2nd: build import
@import "../../../../../../otherSrc/client/we/scss/build";

// our custom component // * NOTE: we can not include partial SCSS files inside the client forlder, atm.

// 3rd: component hooks

@mixin hook-button {
  &--facebook {
    @include _button-color(
      var(--c-facebook),
      var(--c-bg),
      var(--c-bg),
      var(--c-facebook),
      true
    );
    border: 2px solid var(--c-facebook);
  }

  $button-height-large: 9em;
  height: 2.7em;
  //  border: 4px solid red;
  border-radius: var(--border-radius-m);

  &.button--l,
  &.button--large {
    min-height: 2.7em;
    height: 2.7em;

    @include whenSmallerThan(m) {
      padding-inline-end: 1em;
      padding-inline-start: 1em;
    }
  }

  &--loading {
    overflow: hidden;

    .spinner-overlay-wrapper.spinner-overlay-wrapper--absolute {
      min-height: calc(100% + 4px);
      min-width: calc(100% + 4px);
      top: -2px;
      inset-inline-start: -2px;
    }
  }
}

@mixin hook-modal {
  &__header {
    border-bottom: none;
    text-align: center;

    #{$modalSelector}__title {
      padding-inline-end: var(--margin-l);
      padding-inline-start: var(--margin-l);
      color: var(--c-alt);
      font-weight: 600;
      font-size: calc(var(--font-max) * 0.8);
    }
  }

  #{$modalSelector}__body {
    border-bottom: none;
  }

  &--has-header #{$modalSelector}__body {
    padding-top: 0;
  }

  &--has-footer #{$modalSelector}__body {
    padding-bottom: 0;
  }

  // has footer + header + full
  &--has-header#{$modalSelector}--has-footer
    #{$modalSelector}__inner--full
    #{$modalSelector}__body {
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
  }

  &__footer {
    margin-top: var(--margin-2xl);
    padding-top: 0;
    border-top: none;
    justify-content: center;

    .saffron-modal-footer {
      justify-content: center;
    }
  }
}

@mixin hook-form-field-label {
  font-weight: 600;
  font-size: var(--font-l);
}

@mixin hook-field {
  .field__controls {
    border-radius: var(--border-radius);

    &--style-minimal {
      border-radius: 0 !important;
    }
    .repeatable-wrapper {
      border-radius: var(--border-radius);
      border: none;

      .row-buttons-container .button {
        min-width: 38px;
      }
    }
  }

  .field__element {
    border-radius: var(--border-radius);
    padding-top: calc(var(--margin-m) - 1.5px);
    padding-bottom: calc(var(--margin-m) - 1.5px);
    font-size: var(--font-m);
  }
}

@mixin hook-fieldset {
  .form__fieldset-title {
    color: var(--c-alt);
    border: none;
    font-weight: 600;
  }
}

@mixin hook-alert {
  border-radius: var(--border-radius);
}

@mixin hook-notification {
  border-radius: var(--border-radius);
}

@mixin hook-card {
  &__title {
    margin-bottom: var(--margin-l);
  }
}
// 4th: component import
@import "../../../../../../otherSrc/client/we/scss/components";

// 5th: CSS variables declaration/override
:root {
  --global-prefix: "";
  --font-min: calc(10 * var(--base-font-size));
  --font-xs: calc(12 * var(--base-font-size));
  --font-s: calc(14 * var(--base-font-size));
  --font-m: calc(16 * var(--base-font-size));
  --font-l: calc(18 * var(--base-font-size));
  --font-xl: calc(22 * var(--base-font-size));
  --font-2xl: calc(26 * var(--base-font-size));
  --font-3xl: calc(34 * var(--base-font-size));
  --font-max: calc(48 * var(--base-font-size));
  --font-jumbo: calc(52 * var(--base-font-size));
  --base-margin: 1px;

  --margin-xs: calc(2 * var(--base-margin));
  --margin-s: calc(5 * var(--base-margin));
  --margin-m: calc(10 * var(--base-margin));
  --margin: var(--margin-m);
  --margin-l: calc(20 * var(--base-margin));
  --margin-xl: calc(30 * var(--base-margin));
  --margin-2xl: calc(45 * var(--base-margin));
  --margin-max: calc(60 * var(--base-margin));

  --border-radius: calc(8 * var(--base-margin));

  --c-core-lead-1: #58dab8;
  --c-core-lead-2: #00bc6e;
  --c-core-lead-3: #1c7d64;
  --c-core-lead: #1c7d64;
  --c-core-lead-4: #135343;
  --c-core-lead-5: #092a21;

  --c-lead-1: var(--c-core-lead-1);
  --c-lead-2: var(--c-core-lead-2);
  --c-lead-3: var(--c-core-lead-3);
  --c-lead: var(--c-core-lead);
  --c-lead-4: var(--c-core-lead-4);
  --c-lead-5: var(--c-core-lead-5);

  --c-core-alt-1: #573561;
  --c-core-alt-2: #2e1c45;
  --c-core-alt-3: #2e1c45;
  --c-core-alt: #2e1c45;
  --c-core-alt-4: #210d31;
  --c-core-alt-5: #1a082f;

  --c-alt-1: var(--c-core-alt-1);
  --c-alt-2: var(--c-core-alt-2);
  --c-alt-3: var(--c-core-alt-3);
  --c-alt: var(--c-core-alt);
  --c-alt-4: var(--c-core-alt-4);
  --c-alt-5: var(--c-core-alt-5);

  --c-text: #2d3436;
  --c-core-inverse: #2d3436;

  --c-comp-lead-1: #ffdedb;
  --c-comp-lead-2: #ff9a8f;
  --c-comp-lead: #ff8075;
  --c-comp-lead-4: #ff220f;
  --c-comp-lead-5: #db1100;

  --c-comp-alt-1: #f4edd0;
  --c-comp-alt-2: #fce796;
  --c-comp-alt: #e2cd7c;
  --c-comp-alt-4: #d9bd52;
  --c-comp-alt-5: #cbaa2d;

  --c-gray-1: #f8f9fa;
  --c-gray-2: #ccd8dd;
  --c-gray: #b2bec3;
  --c-gray-4: #99a5aa;
  --c-gray-5: #667277;

  --c-success: #1c7d64;
}

// 6th: other custom CSS
*,
*::before,
*::after {
  box-sizing: border-box;
}

// accsibility
#INDmenu-btn {
  top: auto !important;
  bottom: 30vh !important;
}

// 6th: custom css
html,
body {
  background: var(--c-bg);

  &[lang="he"] {
    font-family: "Ploni" !important;
    *,
    input,
    button,
    textarea,
    select,
    button,
    option {
      font-family: "Ploni" !important;
    }
  }
}
